import React, { createContext } from 'react';
import ChildContext from './ChildContext';

const Data = createContext(); // Create the context
const Data2 = createContext(); // Create the context

function ParentContext() {
const name = "faiz";
const sirname = "deshmukh";
  return (
    <Data.Provider value={name}>
    <Data2.Provider value={sirname}>
      <ChildContext/>
    </Data2.Provider>
    </Data.Provider>
  );
}

export default ParentContext;
export {Data, Data2};