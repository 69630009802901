import React from 'react';
import Pagination from './Pagination';

const items = Array.from({ length: 100 }, (_, i) => `Item ${i + 1}`);

function App() {
  return (
    <div className="App">
      <h1>Pagination Example</h1>
      <Pagination items={items} itemsPerPage={10} />
    </div>
  );
}

export default App;
