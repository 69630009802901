import React from 'react';
import ChildProps2 from './ChildProps2'

function ChildProps({ data }) {
  return (
    <div>
    <ChildProps2 data={data} />
    </div>
  );
}
export default ChildProps;