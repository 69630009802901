import React from 'react';
import Fetchdata from './Fetchdata'; // Import the DynamicForm component
import Dataa from './Dataa.json'; // Import your JSON data

function App() {
  return (
    <div>
      <h1>Dynamic Form</h1>
      <Fetchdata Dataa={Dataa} />
    </div>
  );
}

export default App;
