import { useState, useEffect } from "react";

function App() {
  const [result, setResult] = useState([]);

  const getData = () => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((res) => setResult(res));
  };

  useEffect(() => {
    getData();
  });

  return (
    <div className="container">
      <h3 className="mt-3 text-success">
        <center>Export React Table Data into EXCEL Sheet</center>
      </h3>
    </div>
  );
}

export default App;
