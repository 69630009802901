import React, { useContext } from "react";
import { Data, Data2  } from "./ParentContext"; // Import DataContext as the default export

function ChildContext() {
  return (
    <Data.Consumer>
      {(name) => {     // < ye arrow function hai
        return (
          <Data2.Consumer>
            {(sirname) => {     // < ye bhi arrow function hai
              return (          //  call hell hota he iss liye context api ko avoid karte, iss ke jaga par hum usecontext ka use karte hai.
                <h1>    
                  My name is {name} and my sirname is {sirname}     
                </h1>
              );
            }}
          </Data2.Consumer>
        );
      }}
    </Data.Consumer>
  );
}

export default ChildContext;




// import React, { useContext } from 'react';
// import {Data} from './ParentContext'; // Import DataContext as the default export

// function ChildContext() {
//   const my = useContext(Data);
//    return <h1>My name is {my}</h1>
// }

// export default ChildContext;
