import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
  Button,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
import Pdf from "../components/Pdf";
import { Document } from "react-pdf";

const Main = () => {
  return (
    <main id="main">
      {/* ======= About Us Section ======= */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>
          <div className="row content">
            <div className="col-lg-6">
              <p style={{ textAlign: "justify" }}>
                Established in 2021 , InfinitiGrowIT Solutions is an innovative
                IT company specializing in cutting -edge development and design.
                Our expertise extends to guiding startups from conception to
                deployment, excelling in software, app development . We empower
                businesses with unparalleled efficiency and intelligence.
                Partner with InfinitiGrowIT Solutions to shape the future with
                innovative technological solutions.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>
                We offer comprehensive support, from refining ideas to post
                -production services, ensuring exceptional solutions and
                customer satisfaction . Committed to staying at the forefront of
                the rapidly evolving IT industry, we continuously invest in
                development and design, including latest and tech-stacks
                technologies.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End About Us Section */}
      {/* ======= Why Us Section ======= */}
      {/* <section id="why-us" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Resume</h2>
            <Document
              file={Pdf}
              // Other props
            />
          </div>
        </div>
      </section> */}
      {/* End Why Us Section */}

      {/* ======= React JS Section ======= */}
      <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our work</h2>
            <div className="content">
              <h4>
                <strong>FEW OF OUR MOST IMPACTFUL PROJECTS</strong>
              </h4>
            </div>
          </div>
          <div className="row">
            {/* <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
                <h4>
                  <a href={"#"}>Project:1 </a>
                </h4>
                <h4>Tz Mart</h4>
                <p>E-Commerce Shopping Web App.</p>
                <Link to="https://tzmart.in/">
                  {" "}
                  <Button>See Project</Button>
                </Link>
              </div>
            </div> */}

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
               
                <h4>Sahyadri Doodhwala</h4>
                <p>Dairy Products Web App. Various dairy products seller such as milk, curd and many more.</p>
                <Link to="https://sahyadridoodhwala.com/">
                  {" "}
                  <Button>See Project</Button>
                </Link>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
              
                <h4>Harmaal Deals</h4>
                <p>Affiliate Marketing Web App. Different categories items offers.</p>
                <Link to="https://harmaaldeals.shop/">
                  {" "}
                  <Button>See Project</Button>
                </Link>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
               
                <h4>Seedec</h4>
                <p>Online Learning Plateform Web App. Available courses and modules related to engineering fields.</p>
                <Link to="https://seedec.in/">
                  {" "}
                  <Button>See Project</Button>
                </Link>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
               
                <h4>Search4health</h4>
                <p>Hospital Management System. Appoint your seat without waiting in line.</p>
                <Link to="https://search4health.life">
                  {" "}
                  <Button>See Project</Button>
                </Link>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
               
                <h4>ThinkCode</h4>
                <p>Online Courses Web App for professional and students.</p>
                <Link to="/">
                  {" "}
                  <Button>Upcoming</Button>
                </Link>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
                
                <h4>Online adda</h4>
                <p>Online Buy and Sell Product. Your's second hand product selling & buying plateform.</p>
                <Link to="/">
                  {" "}
                  <Button>Upcoming</Button>
                </Link>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
             
                <h4>Medi</h4>
                <p>Medical and medicines management system. Generate bill of material, receipt and stocks.</p>
                <Link to="/">
                  {" "}
                  <Button>Upcoming</Button>
                </Link>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div className="icon-box">
             
                <h4>& so on...</h4>
                <p>Working on some Other categories projects related to different fields.</p>
               
              </div>
            </div>

            <div className="section-title">
              <br></br>
              <br></br>
              <div className="content">
                <h4>
                  Our <strong>Clients</strong>
                </h4>
                <h6>Some of Our Clients</h6>
              </div>
            </div>

            <div id="clients" style={{ display: 'flex', justifyContent: 'center' }}>
  <div class="w3-card-4" style={{ width: '250px', margin: '0 10px' }}>
    <img src="https://png.pngtree.com/element_our/20200702/ourmid/pngtree-hospital-logo-pictures-image_2289643.jpg" alt="Alps" style={{ width: '100%', height: '170px' }} />
    <div class="w3-container w3-center">
    <p>Search4health</p>
    </div>
  </div>
  <div class="w3-card-4" style={{ width: '250px', margin: '0 10px' }}>
    <img src="https://sahyadridoodhwala.com/assets/doodhh-29e9eb00.jpg" alt="Alps" style={{ width: '100%', height: '170px' }} />
    <div class="w3-container w3-center">
    <p>sahyadri doodhwala</p>
    </div>
  </div>
</div>

<div style={{ display: 'flex', justifyContent: 'center' }}>
  <div class="w3-card-4" style={{ width: '250px', margin: '0 10px' }}>
    <img src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRFlXCPRWk9xVxQGwjPvp6l_qtj5EdLKFU9-x0o8kKnjvrtG2ZX" alt="Alps" style={{ width: '100%', height: '170px' }} />
    <div class="w3-container w3-center">
    <p>Harmaal Deals</p>

    </div>
  </div>
  <div class="w3-card-4" style={{ width: '250px', margin: '0 10px' }}>
    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgD9FcNNG18wTVLY8xGSFp8iDUVUwShyHLFg&s" alt="Alps" style={{ width: '100%', height: '170px' }} />
    <div class="w3-container w3-center">
    <p>Seedec</p>
    </div>
  </div>
</div>
          </div>
        </div>
      </section>
      {/* End Services Section */}
      {/* ======= Cta Section ======= */}
      <section id="cta" className="cta">
        <div className="container" data-aos="zoom-in">
          <div className="row">
            <section id="contact" className="contact">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h1>Contact Us</h1>
                  <p></p>
                </div>
                <div className="row">
                  <div className="col-lg-5 d-flex align-items-stretch">
                    <div className="info">
                      <div className="address">
                        <i className="bi bi-geo-alt" />
                        <h4>Location:</h4>
                        <p>Aurangabad Maharashtra, india</p>
                      </div>
                      <div className="email">
                        <i className="bi bi-envelope" />
                        <h4>Email:</h4>
                        <p>infinitigrowitsolutions@gmail.com</p>
                      </div>
                     
                      {/* <iframe
                  src="https://www.google.com/maps/place/Dualsysco+Research+and+Development/@19.885362,75.347052,17z/data=!3m1!4b1!4m6!3m5!1s0x3bdba2a26156490b:0x291378b17bdbea91!8m2!3d19.885357!4d75.3496269!16s%2Fg%2F11bxb653h8?hl=en-US&entry=ttu"
                  frameBorder={0}
                  style={{ border: 0, width: "100%", height: 290 }}
                  allowFullScreen
                /> */}
                    </div>
                  </div>
                  <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                    <form className="php-email-form">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="name">Your Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="name">Your Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">Message</label>
                        <textarea
                          className="form-control"
                          name="message"
                          rows={10}
                          required
                          defaultValue={""}
                        />
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message" />
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit">Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
