import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const openNavbar = () => {
    setOpen(!isOpen)
  }
  return (
    <div>
      
      <header id="header" className="fixed-top header-scrolled  ">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto"><a href={'#/'}>InfinitiGrowIT Solutions</a></h1>
          <nav id="navbar" className={isOpen ? "navbar navbar-mobile" : "navbar"} >
            <ul>
              <li> <a className="nav-link scrollto " href={"#hero"}>Home</a> </li>
              <li><a className="nav-link scrollto" href={"#about"}>About Us</a></li>
              <li><a className="nav-link scrollto" href={"#services"}>Our work</a></li>
              {/* <li><a className="nav-link   scrollto" href={"#portfolio"}>Portfolio</a></li> */}
              {/* <li><a className="nav-link scrollto" href={"#team"}>Team</a></li> */}
              
              <li><a className="nav-link scrollto" href={"#contact"}>Contact Us</a></li>
              <li><a className="getstarted scrollto" href={"#clients"}>Clients</a></li>
            </ul>
            <i className={isOpen ? "bi mobile-nav-toggle bi-x" : "bi bi-list mobile-nav-toggle"}  onClick={openNavbar} />
          </nav>{/* .navbar */}
        </div>
      </header>
      
    </div>
    
  )
}

export default Navbar;