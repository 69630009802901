import React from 'react'
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Router_hooks = () => {
    const location = useLocation();
  return (
    <div>Current Path: {location.pathname}</div>
  )
}

const Router_hooks2 = () => {
    const params = useParams();
    console.log(params);
  return (
    <div>Current Path: {params.id}</div>
  )
}

export default {Router_hooks, Router_hooks2};