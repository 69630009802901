import React, { useState } from 'react';

function Fetchdata({ Dataa }) {
  const [formDataState, setFormDataState] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataState({ ...formDataState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission with formDataState
    console.log(formDataState);

  };

  return (
    <form onSubmit={handleSubmit}>
      {Dataa.field.map((field) => (
        <div>
          <label>{field.label}</label>
            <input
              type={field.type}
              name={field.name}
              onChange={handleChange}
              required={field.required}
            />
          
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
    
  );
}

export default Fetchdata;
