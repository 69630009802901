import React, { createContext } from 'react';
import ChilduseContext from './ChilduseContext';

const Data = createContext(); // Create the context
const Data2 = createContext(); // Create the context

function ParentuseContext() {
const name = "faiz";
const sirname = "deshmukh";
  return (
    <Data.Provider value={name}>
    <Data2.Provider value={sirname}>
      <ChilduseContext/>
    </Data2.Provider>
    </Data.Provider>
  );
}

export default ParentuseContext;
export {Data, Data2};