import React from 'react'
import ChildProps from "./ChildProps";

function ParentProps() {
  const name = "Faiz";

  return (
    <div>
      <ChildProps data={name} />
    </div>
  );
}

export default ParentProps;