import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Pdf from "../components/Pdf"
import Fetch from '../ReactJS_Tasks/Fetch'
import ParentProps from '../ReactJS_Tasks/ParentProps'
import ParentContext from '../ReactJS_Tasks/ParentContext'
import ParentuseContext from '../ReactJS_Tasks/ParentuseContext'
import Excel1 from '../ReactJS_Tasks/Excel1'
import DisplayPagination from '../ReactJS_Tasks/DisplayPagination'
import ExportToExcel from '../ReactJS_Tasks/ExportToExcel'
import SearchBar from '../ReactJS_Tasks/SearchBar'
import Epdf from '../ReactJS_Tasks/Epdf'
import Menu from "../component/Menu";
import Homee from "../pages/Homee";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Router_hooks from "../ReactJS_Tasks/Router_hooks";
import i18n from "../i18n";

const index = () => {
    return (
        <Router>
            <Routes>
                <Route exact path={'/'} element={<Home />} />
                <Route path={'/Pdf'} element={<Pdf />} />
                <Route path={'/fetch'} element={<Fetch />} />
                <Route path={'/ParentProps'} element={<ParentProps />} />
                <Route path={'/ParentContext'} element={<ParentContext />} />
                <Route path={'/ParentuseContext'} element={<ParentuseContext />} />
                <Route path={'/Excel1'} element={<Excel1 />} />
                <Route path={'/DisplayPagination'} element={<DisplayPagination />} />
                <Route path={'/ExportToExcel'} element={<ExportToExcel />} />
                <Route path={'/SearchBar'} element={<SearchBar />} />
                <Route path={'/Epdf'} element={<Epdf />} />
                <Route path={'/homee'} element={<Menu />} />
                <Route path={'/Contact'} element={<Contact />} />
                <Route path={'/homee'} element={<Homee />} />
                <Route path={'/about'} element={<About />} />
                <Route path={'/Router_hooks/:id'} element={<Router_hooks />} />
            </Routes>
        </Router>
    )
}
export default index