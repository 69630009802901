import React from 'react'

function ChildProps2({data}) {
  return (
    <div>
      <p>"Last Component:="{data}</p>
    </div>
  );
}

export default ChildProps2;