import React from 'react'

const Hero = () => {
  return (
    <>
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay={200}>
              <h1>WellCome You!</h1>
              <h2>Grow Your Techs With Us</h2>
              <h3>Software Development & Services</h3>
              <h3>Web Development</h3>
              <h3>App Development</h3>
              <h3>IT Services & Maintenance</h3>
              {/* <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#about" className="btn-get-started scrollto">Get Started</a>
                <a href="#" className="glightbox btn-watch-video"><i className="bi bi-play-circle" /><span>Watch Video</span></a>
              </div> */}
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay={200}>
              <img src="./assets/img/web.png" className="img-fluid animated" alt="Hero" />
            </div>
          </div>
        </div>
      </section>{/* End Hero */}

    </>
  )
}

export default Hero