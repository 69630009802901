import React from 'react'

const Footer = () => {
    return (
        <div>
            <div>
                {/* ======= Footer ======= */}
                <footer id="footer">
                    <div className="footer-newsletter">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <h4>Any Queries</h4>
                                    <p></p>
                                    <form>
                                        <input type="email" name="email" required/><input type="submit" defaultValue="Subscribe" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 footer-contact">
                                    <h3>InfinitiGrowIT Solutions</h3>
                                    <p>
                                   <br />
                                   <strong>Location:</strong>Aurangabad Maharashtra, India<br />
                                    
                                       
                                        <strong>Email:</strong> infinitigrowitsolutions@gmail.com<br />
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#home"}>Home</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#about"}>About us</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#services"}>Our work</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#contact"}>Contact us</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#why-us"}>Our Clients</a></li>
                                    </ul>
                                </div>
                                {/* <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Our Services</h4>
                                    <ul>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#javascript"}>Web Design</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#javascript"}>Web Development</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#javascript"}>Product Management</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#javascript"}>Marketing</a></li>
                                        <li><i className="bx bx-chevron-right" /> <a href={"#javascript"}>Graphic Design</a></li>
                                    </ul>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>My Social Networks</h4>
                                    <p></p>
                                    <div className="social-links mt-3">
                                        <a href={"#javascript"} className="linkedin"><i className="bx bxl-linkedin" /></a>
                                        <a href={"#javascript"} className="twitter"><i className="bx bxl-twitter" /></a>
                                        <a href={"#javascript"} className="facebook"><i className="bx bxl-facebook" /></a>
                                        <a href={"#javascript"} className="instagram"><i className="bx bxl-instagram" /></a>
                                      
                                        
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="container footer-bottom clearfix">
                        <div className="copyright">
                            Copyright © 2021  <strong><span>InfinitiGrowIT Solutions  </span></strong> 
                        </div> -<span>ALL RIGHTS RESERVED</span>
                        <div className="credits">
                            {/* All the links in the footer should remain intact. */}
                            {/* You can delete the links only if you purchased the pro version. */}
                            {/* Licensing information: https://bootstrapmade.com/license/ */}
                            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/ */}
                            {/* Designed by <a href={"#javascript"}>BootstrapMade</a> */}
                        </div>
                    </div>
                </footer>{/* End Footer */}<p />
            </div>
        </div>
    )
}

export default Footer