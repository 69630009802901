import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../containers/Hero'
import Main from '../containers/Main'
import Footer from '../containers/Footer'


const Home = () => {
    return (
        <div className='home-section'>
            <Navbar />
            <Hero />
            <Main />
            <Footer />
            
        </div>
    )
}

export default Home